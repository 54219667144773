import { clog } from '@/utils/clog';
import { loadExternalScript, loadExternalStylesheet } from './embed-utils';
import { getBaseURLClient } from '@/utils/baseURL';

interface DIGOREmbedScripts {
  scriptUrl?: string;
  cssUrl?: string;
  config?: Record<string, any>;
}

(async () => {
  const baseURL = getBaseURLClient();
  clog('hello-digor-embed-bootstrap', baseURL);

  try {
    const url = `${baseURL}/api/digor-embed-statics`;
    const res = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      clog(
        'digor-bootstrap: failed to fetch embed-statics',
        res.statusText,
        url
      );
      return;
    }

    const data: DIGOREmbedScripts = await res.json();

    if (!data.scriptUrl || !data.cssUrl) {
      clog('digor-bootstrap: missing scriptUrl or cssUrl', data);
      return;
    }

    const config = data.config || {};
    const scriptTag = loadExternalScript(data.scriptUrl, 'digor-embed');
    // set config on scriptTag dataset
    Object.keys(config).forEach((key) => {
      scriptTag.dataset[key] = config[key];
    });
    const cssTag = loadExternalStylesheet(data.cssUrl, 'digor-embed-css');

    clog('digor-embed-bootstrap done', scriptTag, cssTag, config);
  } catch (e) {
    console.error('Failed to load digor-embed', e);
  }
})();
