export const isDevEnv = () => {
  try {
    return process.env.NODE_ENV === 'development';
  } catch (e) {
    return false;
  }
};

export const windowDebug = () => {
  try {
    return typeof window !== 'undefined' && (window as any).___DIGOR_DEBUG;
  } catch (e) {
    return false;
  }
};

export const isDebug = () => {
  try {
    return (
      windowDebug() ||
      process.env.DEBUG === 'debug' ||
      process.env.NEXT_PUBLIC_DEBUG === 'debug'
    );
  } catch (e) {
    return false;
  }
};
