import { isDevEnv } from './env';

export const BASE_URL_PROD = 'https://dev.wix.com/digor';

const LOCAL_PORT = 3000;

export const getBaseURLClient = (forceProd = false) => {
  const baseURL =
    isDevEnv() && !forceProd ? `http://localhost:${LOCAL_PORT}` : BASE_URL_PROD;
  return baseURL;
};

export const getBaseURLServer = () => {
  return getBaseURLClient();
};
