import { DIGOREmbedConfig } from '@/types/types';
import { clog } from '@/utils/clog';

export const isDocsRoot = () => {
  const url = new URL(window.location.href);
  return (
    url.hostname === 'dev.wix.com' &&
    (url.pathname === '/docs' ||
      url.pathname === '/docs/' ||
      url.pathname === '/' ||
      url.pathname === '')
  );
};

export const getDocsPortalFromURL = (theurl: string) => {
  const url = new URL(theurl);
  const isDocsUrlWithPortal =
    url.hostname === 'dev.wix.com' && url.pathname.startsWith('/docs');

  if (!isDocsUrlWithPortal) {
    return;
  }

  const pathname = url.pathname;
  // https://dev.wix.com/docs/sdk/articles/working-with-the-sdk/work-with-wix-media
  const parts = pathname.split('/');
  const portal = parts.filter(Boolean).filter((part) => part !== 'docs')[0];
  return portal;
};

const supportedDocsPortalsToAssistantType: Record<string, string> = {
  rest: 'REST',
  sdk: 'SDK',
  'build-apps': 'BUILD_APPS',
  velo: 'VELO',
  'go-headless': 'HEADLESS',
  'develop-websites': 'DEVELOP_WEBSITES',
};

// improve this
export const getAssistantTypeFromURL = () => {
  try {
    const url = new URL(window.location.href);
    const isLocal = url.hostname === 'localhost';

    // is dev.wix.com/apps - i.e dev center
    const isAppsInProd =
      url.hostname === 'dev.wix.com' && url.pathname.startsWith('/apps/');
    if (isAppsInProd) {
      return 'BUILD_APPS'; // switch to unified?
    }

    // is dev.wix.com/docs/xxx
    const isDocsInProd =
      url.hostname === 'dev.wix.com' && url.pathname.startsWith('/docs');

    const isDocsRoot =
      url.hostname === 'dev.wix.com' &&
      (url.pathname === '/docs' ||
        url.pathname === '/docs/' ||
        url.pathname === '/');

    if (isDocsRoot) {
      return 'UNIFIED';
    }

    if (!isDocsInProd && !isLocal) {
      return;
    }

    const pathname = url.pathname;
    // https://dev.wix.com/docs/sdk/articles/working-with-the-sdk/work-with-wix-media
    const parts = pathname.split('/');
    const portal = parts.filter(Boolean).filter((part) => part !== 'docs')[0];

    if (portal in supportedDocsPortalsToAssistantType) {
      return supportedDocsPortalsToAssistantType[portal];
    }
  } catch (e) {
    clog?.(
      'digor-embed: could not derive assistant type from URL:',
      e,
      window.location.href
    );
    return;
  }

  return;
};

export const isSupportedDocsPortal = (url: string) => {
  const urlPortal = getDocsPortalFromURL(url);
  if (!urlPortal) {
    return false;
  }
  return urlPortal in supportedDocsPortalsToAssistantType;
};

export const isCurrentPortal = (url: string) => {
  const currentPortal = getDocsPortalFromURL(window.location.href);
  const urlPortal = getDocsPortalFromURL(url);
  return currentPortal === urlPortal;
};

export const isEmbeddedInDocs = () => {
  return !!getDocsPortalFromURL(window.location.href) || isDocsRoot();
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const findMenuNodes = (linkPath: string) => {
  var menuItemContainer = document.querySelector(
    `[data-hook="${linkPath}_menu_item_container"]`
  );
  var menuItem = document.querySelector(`[data-hook="${linkPath}_menu_item"]`);
  return [menuItemContainer, menuItem];
};

export const isMenuItemOpen = (linkPath: string) => {
  // considered open if has menuItem children, find all children by linkPath
  const children = document.querySelectorAll(`[data-hook^="${linkPath}/"]`);
  return children.length > 0;
};

export const navigateToPath = async (fullURL: string, linkPath: string) => {
  if (!isEmbeddedInDocs()) {
    // if not inside docs, open in new tab
    clog('navigateToPath: not inside docs, opening in new tab:', {
      fullURL,
      linkPath,
    });
    window.open(fullURL, '_blank');
  } else if (!isCurrentPortal(fullURL)) {
    clog('navigateToPath: not current portal, full page load:', {
      fullURL,
      linkPath,
    });
    window.location.href = fullURL;
  } else {
    await clickAllMenuNodeParents(fullURL, linkPath);
  }
};

export const clickAllMenuNodeParents = async (
  fullURL: string,
  linkPath: string
) => {
  // all link paths:
  const linkParts = linkPath.split('/');
  const allLinkPaths = linkParts
    .map((part, index) => linkParts.slice(0, index + 1).join('/'))
    .filter(Boolean);
  // clog('allLinkPaths:', allLinkPaths);

  let clickedSomething = false;
  for (let i = 0; i < allLinkPaths.length; i++) {
    const path = allLinkPaths[i];
    if (isMenuItemOpen(path)) {
      // clog('clickAllMenuNodeParents: already open:', path);
      continue;
    }

    const [menuItemContainer, menuItem] = findMenuNodes(path);
    if (menuItem) {
      // clog('clicking:', path);
      (menuItem as any)?.click();
      clickedSomething = true;
      await sleep(100);
    } else {
      clog(
        'clickAllMenuNodeParents: not clicking - could not find menu item:',
        path
      );
    }
  }

  if (!clickedSomething) {
    clog(
      'clickAllMenuNodeParents: nothing-clicked, opening in new tab:',
      linkPath,
      fullURL
    );
    window.open(fullURL, '_blank');
  }
};

export const removeById = (id: string) => {
  const existing = document.getElementById(id);
  if (existing) {
    existing.remove();
  }
};

export const createDivAndAppend = (id: string, parentSelector?: string) => {
  removeById(id);
  const div = document.createElement('div');
  div.id = id;
  const parent = parentSelector
    ? document.querySelector(parentSelector)
    : document.body;
  if (parent) {
    parent.appendChild(div);
  } else {
    document.body.appendChild(div);
  }

  return div;
};

export const getConfigFromScript = (
  id: string
): Record<string, string | undefined | boolean> => {
  const script = document.getElementById(id);
  if (!script) {
    return {};
  }

  clog('digor-embed: script.dataset', script.dataset);

  return {
    ...script.dataset,
  };
};

export const getConfigFromDigorScripts = (): DIGOREmbedConfig => {
  const config1 = getConfigFromScript('wix-digor-bootstrap');
  const config2 = getConfigFromScript('digor-embed');

  return {
    ...config1,
    ...config2,
  };
};

export const loadExternalScript = (url: string, id: string) => {
  // remove the script if it already exists
  removeById(id);

  const script = document.createElement('script');
  script.id = id;
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
  return script;
};

export const loadExternalStylesheet = (url: string, id: string) => {
  // remove the stylesheet if it already exists
  removeById(id);

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  link.id = id;
  document.head.appendChild(link);
  return link;
};
